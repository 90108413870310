<template>
  <div class="group-bank-add">
    <b-card>
      <b-form
        :class="{ 'invalid-form': isSubmitted }"
        @submit.prevent
      >
        <b-row>
          <b-col md="4">
            <input-group
              v-model="dataInfo.name"
              :value="dataInfo.name"
              :label="'Bank Name'"
              image="/images/members/bankName.svg"
              :make-required="true"
            />
          </b-col>
          <b-col md="4">
            <input-group
              v-model="dataInfo.branchName"
              :value="dataInfo.branchName"
              :label="'Branch'"
              image="/images/members/bankBranch.svg"
              :make-required="true"
            />
          </b-col>
          <b-col md="4">
            <input-group
              v-model="dataInfo.accountNumber"
              :value="dataInfo.accountNumber"
              :label="'Account No.'"
              :type="'tel'"
              image="/images/members/accountNo.svg"
              :make-required="true"
            />
          </b-col>
          <b-col md="4">
            <input-group
              v-model="dataInfo.ifsc"
              :value="dataInfo.ifsc"
              :label="'IFSC'"
              :mask="'AAAA0XXXXXX'"
              image="/images/members/ifsc.svg"
              :make-required="true"
            />
          </b-col>
          <b-col md="4">
            <input-group
              v-model="dataInfo.contactPerson"
              :value="dataInfo.contactPerson"
              :label="'Contact Person'"
              image="/images/members/contactPerson.svg"
              :make-required="true"
            />
          </b-col>
          <b-col md="4">
            <input-group
              v-model="dataInfo.mobileNo"
              :value="dataInfo.mobileNo"
              :label="'Mobile'"
              :type="'tel'"
              :mask="'#####-#####'"
              image="/images/members/mobile.svg"
              :make-required="true"
            />
          </b-col>
          <b-col md="4">
            <input-group
              v-model="dataInfo.landlineNo"
              :value="dataInfo.landlineNo"
              :label="'Other Phone'"
              :type="'tel'"
              :mask="'#####-#####'"
              image="/images/members/otherPh.svg"
            />
          </b-col>
          <b-col md="12">
            <app-collapse
              accordion
              type="margin"
              class="mt-2"
            >
              <app-collapse-item
                :title="'Address'"
                class="bo-yellow bg-yellow"
              >
                <b-row>
                  <b-col md="4">
                    <select-group
                      v-model="dataInfo.address.stateId"
                      :value="dataInfo.address.stateId"
                      :label="'State'"
                      :options="state"
                      option-value="id"
                      option-label="name"
                      image="/images/members/state.svg"
                      @update="getDistricts('yes')"
                    />
                  </b-col>
                  <b-col md="4">
                    <select-group
                      v-model="dataInfo.address.districtId"
                      :value="dataInfo.address.districtId"
                      :label="'District'"
                      :options="district"
                      option-value="id"
                      option-label="name"
                      image="/images/members/district.svg"
                      @update="
                        getSubDistricts('yes');
                        getBlock('yes');
                      "
                    />
                  </b-col>
                  <b-col md="4">
                    <select-group
                      v-model="dataInfo.address.subDistrictId"
                      :value="dataInfo.address.subDistrictId"
                      :label="'Tehsil/Taluk'"
                      :options="taluk"
                      option-value="id"
                      option-label="name"
                      image="/images/members/tahsil.svg"
                    />
                  </b-col>
                  <b-col md="4">
                    <select-group
                      v-model="dataInfo.address.blockId"
                      :value="dataInfo.address.blockId"
                      :label="'Block'"
                      :options="block"
                      option-value="id"
                      option-label="name"
                      image="/images/members/state.svg"
                      @update="getPanchayat('yes')"
                    />
                  </b-col>
                  <b-col md="4">
                    <select-group
                      v-model="dataInfo.address.panchayatId"
                      :value="dataInfo.address.panchayatId"
                      :label="'Panchayat'"
                      :options="panchayat"
                      option-value="id"
                      option-label="name"
                      image="/images/members/panchayat.svg"
                    />
                  </b-col>
                  <b-col md="4">
                    <input-group
                      v-model="dataInfo.address.cityOrVillage"
                      :value="dataInfo.address.cityOrVillage"
                      :label="'Town / Village'"
                      image="/images/members/village.svg"
                    />
                  </b-col>
                  <b-col md="4">
                    <input-group
                      v-model="dataInfo.address.pinCode"
                      :value="dataInfo.address.pinCode"
                      :type="'tel'"
                      :label="'Pin Code'"
                      image="/images/members/pinCode.svg"
                    />
                  </b-col>
                  <b-col md="4">
                    <input-group
                      v-model="dataInfo.address.email"
                      :value="dataInfo.address.email"
                      :label="'Email'"
                      image="/images/members/email.svg"
                    />
                  </b-col>
                </b-row>
              </app-collapse-item>
            </app-collapse>
          </b-col>
          <b-col
            md="12"
            class="text-center mt-2"
          >
            <button
              class="btn btn-primary"
              @click="saveBank"
            >
              Save
            </button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </div>
</template>
<script>
import {
  BRow, BCol, BCard, BForm,
} from 'bootstrap-vue'
import InputGroup from '@/@core/layouts/shared/InputGroup.vue'
import SelectGroup from '@/@core/layouts/shared/SelectGroup.vue'
import AppCollapse from '@/@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@/@core/components/app-collapse/AppCollapseItem.vue'
import {
  appBankById,
  appGetBlocks,
  appGetDistricts,
  appGetPanchayat,
  appGetStates,
  appGetSubDistrict,
  appUpdateBank,
} from '@/@core/services/groups'
import {
  hideLoader, showDangerNotification, showLoader, showSuccessNotification,
} from '@/@core/comp-functions/ui/app'
import { goToPath } from '@/auth/utils'

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BCard,
    InputGroup,
    SelectGroup,
    AppCollapse,
    AppCollapseItem,
  },
  data() {
    return {
      dataInfo: {
        address: {},
      },
      state: [],
      district: [],
      taluk: [],
      block: [],
      panchayat: [],
      bankId: this.$route.params.bankId,
      isSubmitted: false,
    }
  },
  computed: {
    unionSelected() {
      return this.$store.getters['groups/currentUnion']
    },
  },
  mounted() {
    this.getStates()
    if (this.bankId) {
      this.getBankById()
    }
  },
  methods: {
    getBankById() {
      showLoader()
      appBankById({
        id: this.bankId,
      }).then(({ data }) => {
        if (data.succeeded) {
          this.dataInfo = data.data
          this.setAddressFields()
        } else {
          showDangerNotification(this, data.message)
        }
        hideLoader()
      })
    },
    setAddressFields() {
      if (this.dataInfo.address.stateId) {
        this.getDistricts()
      }
      if (this.dataInfo.address.districtId) {
        this.getSubDistricts()
        this.getBlock()
      }
      if (this.dataInfo.address.blockId) {
        this.getPanchayat()
      }
    },
    saveBank() {
      this.isSubmitted = true
      this.$nextTick(() => {
        if (window.ManMudra.validations.get()) {
          window.ManMudra.validations.focus()
          return
        }
        showLoader()
        this.isSubmitted = false
        this.dataInfo.unionId = this.unionSelected.id
        appUpdateBank(this.dataInfo).then(({ data }) => {
          if (data.succeeded) {
            goToPath(this, 'group-bank-list')
            showSuccessNotification(this, data.message)
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
      })
    },
    getStates() {
      appGetStates().then(({ data }) => {
        if (data.succeeded) {
          this.state = data.data
        } else {
          showDangerNotification(this, data.message)
        }
      })
    },
    getDistricts(reset) {
      if (reset) {
        this.dataInfo.address.districtId = null
        this.district = []
        this.taluk = []
        this.panchayat = []
        this.block = []
        this.dataInfo.address.subDistrictId = null
        this.dataInfo.address.blockId = null
        this.dataInfo.address.panchayatId = null
      }
      appGetDistricts({
        stateId: this.dataInfo.address.stateId,
      }).then(({ data }) => {
        if (data.succeeded) {
          this.district = data.data
        } else {
          showDangerNotification(this, data.message)
        }
      })
    },
    getSubDistricts(reset) {
      if (reset) {
        this.taluk = []
        this.block = []
        this.panchayat = []
        this.dataInfo.address.subDistrictId = null
        this.dataInfo.address.blockId = null
        this.dataInfo.address.panchayatId = null
      }
      appGetSubDistrict({
        districtId: this.dataInfo.address.districtId,
      }).then(({ data }) => {
        if (data.succeeded) {
          this.taluk = data.data
        } else {
          showDangerNotification(this, data.message)
        }
      })
    },
    getBlock(reset) {
      if (reset) {
        this.panchayat = []
        this.block = []
        this.dataInfo.address.panchayatId = null
        this.dataInfo.address.blockId = null
      }
      appGetBlocks({
        districtId: this.dataInfo.address.districtId,
      }).then(({ data }) => {
        if (data.succeeded) {
          this.block = data.data
        } else {
          showDangerNotification(this, data.message)
        }
      })
    },
    getPanchayat(reset) {
      if (reset) {
        this.panchayat = []
        this.dataInfo.address.panchayatId = null
      }
      appGetPanchayat({
        blockId: this.dataInfo.address.blockId,
      }).then(({ data }) => {
        if (data.succeeded) {
          this.panchayat = data.data
        } else {
          showDangerNotification(this, data.message)
        }
      })
    },
  },
}
</script>

<style>
.group-bank-add .bg-yellow .card-header {
  background: #fffde7 !important;
}

.group-bank-add .bo-yellow {
  border-left: 3px solid #c7c32f !important;
}
</style>
